import { Section, Contain, Content, Text, Spacer } from './goalStyles'


const Goal = () => {
  return (
    <Section>
      <Contain>
        <Content>
          <Text>
            At Nebulei, our goal is to build a thriving, interconnected community of thinkers, creators, and problem-solvers.
            We believe that the greatest challenges in the world today require a convergence of diverse perspectives, skills, 
            and knowledge from all corners of human endeavor. Nebulei exists to break down the silos that often confine innovation, 
            encouraging collaboration between individuals from fields as varied as aerospace engineering, biology, and beyond.
            Our aim is not just to solve problems, but to approach them with a sense of curiosity, boldness, and the relentless 
            pursuit of better solutions. We want to create a space where ideas are explored freely, without the constraints of 
            rigid frameworks, where anyone—regardless of background—can contribute to breakthroughs that might otherwise seem 
            unreachable.
            <Spacer />
            We are also deeply committed to fostering a sense of healthy competition. Through gamification and dynamic challenges, 
            we aim to push our community to not just settle for incremental improvements but to strive for game-changing innovations. 
            Nebulei isn’t just a place to work on problems—it’s a place to challenge yourself, grow, and see just how far you can go 
            when you’re surrounded by others equally driven to make an impact. At the heart of Nebulei is the belief that everyone, 
            no matter their discipline, can contribute something significant.
            <Spacer />
            Whether you’re a seasoned expert or just starting out, Nebulei provides the tools, the support, and the inspiration to 
            turn ideas into reality. Our goal is simple: to be the catalyst for progress and to empower a global community of visionary 
            minds to solve the problems that matter most.
          </Text>
        </Content>
      </Contain>
    </Section>
  )
}

export default Goal