import { Foot, Contain, FooterText, FootLinks, FootLink } from './styles'


const Footer = () => {
  return (
    <Foot>
      <Contain>
        <FooterText>
          &copy; {new Date().getFullYear()} Nebulei. All Rights Reserved.
        </FooterText>

        <FootLinks>
          <FootLink to='/privacy'>Privacy Policy</FootLink>
          <FootLink to='/tof'>Terms of Service</FootLink>
        </FootLinks>
      </Contain>
    </Foot>
  )
}

export default Footer
