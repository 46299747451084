import styled from 'styled-components'


export const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: ${(props) => props.theme.spacing.xxl} 0;

  @media (max-width: 768px) {
    min-height: auto;
    padding: ${(props) => props.theme.spacing.lg} 0;
  }
`

export const Contain = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: ${(props) => props.theme.spacing.lg};
  border-radius: ${(props) => props.theme.borderRadius.lg};

  @media (max-width: 768px) {
    padding: ${(props) => props.theme.spacing.md};
  }
`

export const Content = styled.div`
  margin: 0;
  padding: ${(props) => props.theme.spacing.md};
  line-height: 1.8;
  color: ${(props) => props.theme.textColor.primary};
`

export const Heading = styled.h1`
  color: ${(props) => props.theme.textColor.primary};
  font-family: ${(props) => props.theme.font.secondary};
  font-weight: ${(props) => props.theme.fontWeight.extrabold};
  font-size: ${(props) => props.theme.fontSize.xxxl};
  text-align: center;
  margin-bottom: ${(props) => props.theme.spacing.lg};

  @media (max-width: 768px) {
    font-size: ${(props) => props.theme.fontSize.xxl};
  }
`

export const SubHeading = styled.h2`
color: ${(props) => props.theme.textColor.primary};
font-family: ${(props) => props.theme.font.secondary};
font-weight: ${(props) => props.theme.fontWeight.semibold};
font-size: ${(props) => props.theme.fontSize.xl};
margin-top: ${(props) => props.theme.spacing.lg};

@media (max-width: 768px) {
  font-size: ${(props) => props.theme.fontSize.lg};
}
`

export const Paragraph = styled.p`
  color: ${(props) => props.theme.textColor.secondary};
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.base};
  line-height: 1.8;
  margin-top: ${(props) => props.theme.spacing.sm};
  margin-bottom: ${(props) => props.theme.spacing.md};
`

export const List = styled.ul`
  color: ${(props) => props.theme.textColor.secondary};
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.base};
  margin: ${(props) => props.theme.spacing.md} 0;
  padding-left: ${(props) => props.theme.spacing.lg};
  list-style-type: disc;
`

export const ListItem = styled.li`
  margin-bottom: ${(props) => props.theme.spacing.sm};
`

export const Link = styled.a`
  color: ${(props) => props.theme.color.primary};
  font-weight: ${(props) => props.theme.fontWeight.semibold};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.color.secondary};
  }
`
