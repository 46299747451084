import { Header, Text, Blinker, ProjectedDate, Date } from './styles'

const Announcement = () => {
  return (
    <Header>
      <Text>Nebulei is currently under development</Text>
      <Blinker />
      <ProjectedDate>
        Projected Date: <Date>November 10th, 2024</Date>
      </ProjectedDate>
    </Header>
  )
}

export default Announcement
