import { Section, Contain, Content, Header } from './heroStyles'


const Hero = () => {
  return (
    <Section>
    <Contain>
      <Content>
        <Header>
          Privacy Policy
        </Header>
      </Content>
    </Contain>
    </Section>
  )
}

export default Hero
