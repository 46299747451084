import SEO from '../../../../common/seo'
import { HeroSection, FeaturesSection } from './sect/index'


const Home = () => {
  return (
    <>
    <SEO
      title="Nebulei | Empowering Innovation and Collaboration"
      description="Discover Nebulei's mission to unite diverse professionals, solve global challenges, and foster innovation through collaboration and competition. Learn how we're driving progress in various fields with optimized tools and gamification."
      keywords="Nebulei, innovation, collaboration, problem-solving, gamification, mission, goals, professional community, global challenges, technological solutions"
    />
    <HeroSection />
    <FeaturesSection />
    </>
  )
}

export default Home