import { ReactTyped as Typed } from 'react-typed'

import { Section, Contain, Content, Header, TypedText, EarthText } from './heroStyles'


const Hero = () => {
  return (
    <Section>
      <Contain>
        <Content>
          <Header>
            Empowering
            <br />
            <TypedText>
              <Typed
                strings={['Innovators', 'Creators', 'Researchers', 'Visionaries', 'Pioneers']}
                typeSpeed={50}
                backSpeed={30}
                loop
              />
            </TypedText>
            <br />
            of{' '}<EarthText>Earth</EarthText>.
          </Header>
        </Content>
      </Contain>
    </Section>
  )
}

export default Hero
