import styled, { keyframes } from 'styled-components'


const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

export const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
  background: linear-gradient(45deg, ${(props) => props.theme.color.primary}, ${(props) => props.theme.color.secondary}, ${(props) => props.theme.color.accent});
  background-size: 200% 200%;
  animation: ${gradientAnimation} 8s ease infinite;

  @media (max-width: 768px) {
    min-height: 20vh;
  }
`

export const Contain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: ${(props) => props.theme.spacing.lg};
  text-align: center;

  @media (max-width: 768px) {
    padding: ${(props) => props.theme.spacing.md};
    max-width: 100%;
  }
`

export const Content = styled.div`
  margin: 0;
  padding: ${(props) => props.theme.spacing.md};
  line-height: 1.5;

  @media (max-width: 768px) {
    padding: ${(props) => props.theme.spacing.sm};
  }
`

export const Header = styled.h1`
  color: ${(props) => props.theme.textColor.white};
  font-family: ${(props) => props.theme.font.secondary};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSize.xxxl};

  @media (max-width: 768px) {
    font-size: ${(props) => props.theme.fontSize.xxl};
  }

  @media (max-width: 480px) {
    font-size: ${(props) => props.theme.fontSize.lg};
  }
`
