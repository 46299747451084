import SEO from '../../../../common/seo'

import { HeroSection, PrivacySection } from './sect/index'


const Privacy = () => {
  return (
    <>
    <SEO
      title="Privacy Policy | Nebulei"
      description="Read Nebulei's Privacy Policy to learn how we protect your personal information, ensure data security, and maintain transparency in our practices. Discover how your privacy is a priority as you engage with our innovative platform."
      keywords="Nebulei, privacy policy, data security, personal information, data protection, transparency, privacy practices, user privacy, data privacy, Nebulei privacy"
    />
    <HeroSection />
    <PrivacySection />
    </>
  )
}

export default Privacy