import { useState } from 'react'

import { Section, Contain, LogoWrapper, Logo, Content, Heading, Description } from './featuresStyles'
import { catalyst, guild, duel } from '../../../../../../assets/index'


export const features = [
  {
    name: 'Catalyst',
    description: 'A global collaboration tool for users to accelerate innovation and problem-solving by working together on challenges.',
    logo: catalyst
  },
  {
    name: 'Guild',
    description: 'A community space where users form alliances to collaborate on shared goals and projects.',
    logo: guild
  },
  {
    name: 'Duel',
    description: 'A competitive feature where users challenge each other in skill-based tasks to showcase expertise.',
    logo: duel
  }
]

const Features = () => {
  const [loadedImages, setLoadedImages] = useState([])

  const handleImageLoad = (index) => {
    setLoadedImages((prev) => [...prev, index])
  }

  return (
    <Section>
      {features.map((feature, index) => (
        <Contain key={feature.name} $reverse={index % 2 !== 0}>
          <LogoWrapper $reverse={index % 2 !== 0}>
            <Logo
              src={feature.logo}
              alt={feature.name}
              className={loadedImages.includes(index) ? 'loaded' : ''}
              onLoad={() => handleImageLoad(index)}
            />
          </LogoWrapper>
          <Content $reverse={index % 2 !== 0}>
            <Heading>{feature.name}</Heading>
            <Description>{feature.description}</Description>
          </Content>
        </Contain>
      ))}
    </Section>
  )
}

export default Features
