import { Routes, Route } from 'react-router-dom'

import useScrollToTop from './hooks/useScrollToTop'
import { mainPath } from './container/paths'
import Announcement from './components/Announce'
import Navbar from './components/Navbar/index'
import Footer from './components/Footer/index'
import Main from './container/Main/index'


function App() {
  useScrollToTop()

  return (
    <>
    <Announcement />
    <Navbar />

    <Routes>
      <Route path={mainPath + "*"} element={<Main />} />
    </Routes>

    <Footer />
    </>
  )
}

export default App