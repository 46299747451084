import styled from 'styled-components'
import { Link } from 'react-router-dom'


export const Foot = styled.footer`
  background-color: ${(props) => props.theme.color.transparent};
  padding: ${(props) => props.theme.spacing.md};
  border-top: 4px solid ${(props) => props.theme.borderColor.light};
  border-color: ${(props) => props.theme.borderColor.light};
  margin-top: ${(props) => props.theme.spacing.xl};
`

export const Contain = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: ${(props) => props.theme.spacing.xs};
  margin-bottom: ${(props) => props.theme.spacing.xs};

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`

export const FooterText = styled.p`
  color: ${(props) => props.theme.textColor.secondary};
  font-family: ${(props) => props.theme.font.secondary};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  font-size: ${(props) => props.theme.fontSize.sm};
  margin: 0;

  @media (max-width: 768px) {
    margin-bottom: ${(props) => props.theme.spacing.md};
  }
`

export const FootLinks = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: ${(props) => props.theme.spacing.base};
    flex-direction: column;
  }
`

export const FootLink = styled(Link)`
  color: ${(props) => props.theme.textColor.primary};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSize.sm};
  text-decoration: none;
  border-radius: ${(props) => props.theme.borderRadius.md};
  padding: ${(props) => props.theme.spacing.xs};
  margin-left: ${(props) => props.theme.spacing.lg};

  &:hover {
    color: ${(props) => props.theme.color.primary};
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin-bottom: ${(props) => props.theme.spacing.md};
  }
`

