import { Nav, Contain, Brand, Logo, Name, BetaText, NavLinks, NavLink, StyledBrandLink } from './styles'
import logo from '../../assets/logo.svg'


const Navbar = () => {
  return (
    <Nav>
      <Contain>
        <StyledBrandLink to='/'>
          <Brand>
            <Logo src={logo} alt='Nebulei' />
            <Name>
              Nebulei
              <BetaText>Beta</BetaText>
            </Name>
          </Brand>
        </StyledBrandLink>

        <NavLinks>
          <NavLink to='/'>Home</NavLink>
          <NavLink to='/goal'>Goal</NavLink>
        </NavLinks>
      </Contain>
    </Nav>
  )
}

export default Navbar
