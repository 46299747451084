import styled, { keyframes } from 'styled-components'


const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`

const wobble = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0deg);
  }
`

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.xxl} ${({ theme }) => theme.spacing.lg};
`

export const Contain = styled.div`
  display: flex;
  flex-direction: ${({ $reverse }) => ($reverse ? 'row-reverse' : 'row')};
  align-items: center;
  justify-content: space-between;
  min-height: 30vh;
  width: 100%;
  max-width: 1200px;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  padding: ${({ theme }) => theme.spacing.lg};
  border-radius: ${({ theme }) => theme.borderRadius.lg};

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    flex-direction: column;
    text-align: center;
    min-height: 20vh;
  }
`

export const LogoWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: ${({ $reverse }) => ($reverse ? 'flex-end' : 'flex-start')};
  padding: ${({ theme }) => theme.spacing.md};
`

export const Logo = styled.img`
  width: 300px;
  height: 300px;
  object-fit: contain;
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.6s ease, transform 0.6s ease;

  &.loaded {
    opacity: 1;
    transform: scale(1);
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    width: 80px;
    height: 80px;
  }

  &:hover {
    animation: ${pulse} 2s infinite ease-in-out, ${wobble} 1.5s infinite ease-in-out;
  }
`

export const Content = styled.div`
  flex: 2;
  max-width: 500px;
  padding: ${({ theme }) => theme.spacing.lg};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ $reverse }) => ($reverse ? 'flex-end' : 'flex-start')};

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}) {
    align-items: center;
  }
`

export const Heading = styled.h2`
  color: ${({ theme }) => theme.textColor.primary};
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.xl};
`

export const Description = styled.p`
  color: ${({ theme }) => theme.textColor.secondary};
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.lg};
  margin-top: ${({ theme }) => theme.spacing.md};
`
