import { Routes, Route } from 'react-router-dom'

import { homePath, goalPath, termsPath, privacyPath } from './paths'
import { Home, Goal, Terms, Privacy } from './views/index'
import NotFound from './NotFound'


const Main = () => {
  return (
    <>
    <Routes>
      <Route path={homePath} element={<Home />} />
      <Route path={goalPath} element={<Goal />} />
      <Route path={termsPath} element={<Terms />} />
      <Route path={privacyPath} element={<Privacy />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
    </>
  )
}

export default Main