import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import App from './App'


import { ThemeProvider } from 'styled-components'
import { theme } from './css/theme.js'
import './css/index.css'


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
  <HelmetProvider>
  <Router>
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
  </Router>
  </HelmetProvider>
  </React.StrictMode>
)
