export const theme = {
  color: {
    primary: '#A172FD',
    secondary: '#973AA8',
    accent: '#D4CDF4',

    success: '#25A244',
    warning: '#FCA311',
    danger: '#A4161A',

    transparent: 'transparent',
    white: 'white',
    black: 'black',
    grey: {
      100: '#f2f3f5',
      200: '#ebedef',
      300: '#e3e5e8',
      400: '#d4d7dc',
      500: '#72767d',
      600: '#4f545c',
      700: '#36393f',
      800: '#2f3136',
      900: '#202225',
    },
  },

  backgroundColor: {
    primary: '#A172FD',
    secondary: '#2f3136',
    accent: '#d4cdf4',
    dark: '#242423',
    light: '#f2f3f5',
    success: '#25A244',
    warning: '#FCA311',
    danger: '#A4161A',
  },

  textColor: {
    primary: '#212529',
    secondary: '#555555',
    white: 'white',
    black: 'black',
    muted: '#ADB5BD',
    success: '#6EDE8A',
    warning: '#F5CB5C',
    danger: '#E5383B',
  },

  borderColor: {
    dark: '#242423',
    light: '#E5E5E5',
  },

  font: {
    primary: "'Expletus Sans', sans-serif",
    secondary: "'Newsreader', serif",
  },

  fontSize: {
    base: '16px',
    xm: '10px',
    sm: '14px',
    md: '20px',
    lg: '24px',
    xl: '32px',
    xxl: '40px',
    xxxl: '50px',
    huge: '60px',
  },

  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
  },

  spacing: {
    base: '12px',
    xxs: '2px',
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
    xxl: '48px',
  },

  breakpoint: {
    mobile: '480px',
    tablet: '768px',
    desktop: '1024px',
    largeDesktop: '1200px',
  },

  borderRadius: {
    sm: '2px',
    md: '4px',
    lg: '8px',
    xl: '15px',
    pill: '50px',
    circle: '50%',
  },

  opacity: {
    none: '1',
    light: '0.5',
    medium: '0.75',
    strong: '0.9',
    subtle: '0.25',
  },

  shadows: {
    small: '0 1px 3px rgba(0, 0, 0, 0.12)',
    medium: '0 3px 6px rgba(0, 0, 0, 0.16)',
    large: '0 10px 20px rgba(0, 0, 0, 0.2)',
  },
}
