import styled from 'styled-components'
import { Link } from 'react-router-dom'


export const Nav = styled.nav`
  background-color: ${(props) => props.theme.color.transparent};
  padding: ${(props) => props.theme.spacing.md};
`

export const Contain = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    justify-content: center;
  }
`

export const Brand = styled.div`
  display: flex;
  align-items: center;
`

export const Logo = styled.img`
  height: 50px;
  align-items: center;
  margin-right: ${(props) => props.theme.spacing.sm};
`

export const Name = styled.h1`
  color: ${(props) => props.theme.textColor.primary};
  font-family: ${(props) => props.theme.font.secondary};
  font-weight: ${(props) => props.theme.fontWeight.extrabold};
  font-size: ${(props) => props.theme.fontSize.xl};
  position: relative;
`

export const BetaText = styled.span`
  color: ${(props) => props.theme.textColor.primary};
  background-color: ${(props) => props.theme.backgroundColor.light};
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.sm};
  opacity: ${(props) => props.theme.opacity.strong};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  padding: 2px 4px;
  position: absolute;
  top: 5px;
  right: -45px;
`

export const NavLinks = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`

export const NavLink = styled(Link)`
  color: ${(props) => props.theme.textColor.secondary};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSize.md};
  text-decoration: none;
  border-radius: ${(props) => props.theme.borderRadius.lg};
  padding: 8px 16px;
  margin-left: ${(props) => props.theme.spacing.lg};
  transition: color 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.backgroundColor.light};
  }
`

export const StyledBrandLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`
