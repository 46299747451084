import styled from 'styled-components'

export const Header = styled.div`
  background-color: ${(props) => props.theme.backgroundColor.light};
  border-bottom: 2px solid ${(props) => props.theme.borderColor.light};
  opacity: ${(props) => props.theme.opacity.strong};
  padding: ${(props) => props.theme.spacing.sm} ${(props) => props.theme.spacing.sm};
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Text = styled.span`
  color: ${(props) => props.theme.textColor.primary};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSize.sm};
  margin-right: ${(props) => props.theme.spacing.lg};

  @media (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    font-size: ${(props) => props.theme.fontSize.xm};
  }
`

export const Blinker = styled.span`
  background-color: ${(props) => props.theme.color.primary};
  display: inline-block;
  border-radius: 50%;
  margin-right: ${(props) => props.theme.spacing.xs};
  padding: ${(props) => props.theme.spacing.xs};
  animation: blink 1s ease-in-out infinite;

  @keyframes blink {
    0%, 100% {
      background-color: ${(props) => props.theme.color.primary};
    }
    50% {
      background-color: ${(props) => props.theme.color.secondary};
    }
  }
`

export const ProjectedDate = styled.span`
  color: ${(props) => props.theme.textColor.secondary};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  font-size: ${(props) => props.theme.fontSize.sm};
  margin-left: ${(props) => props.theme.spacing.sm};

  @media (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    font-size: ${(props) => props.theme.fontSize.xm};
    margin-left: ${(props) => props.theme.spacing.xs};
  }
`

export const Date = styled.span`
  color: ${(props) => props.theme.textColor.secondary};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSize.sm};
  margin-left: ${(props) => props.theme.spacing.xs};

  @media (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    font-size: ${(props) => props.theme.fontSize.xm};
    margin-left: ${(props) => props.theme.spacing.xxs};
  }
`
